<div *ngIf="!loading" class="container my-4">
  <div class="row justify-content-center pb-4">
    <div class="col-lg-8">
      <div *ngIf="currentStep === InsuranceStep.CONFIRM_INSURANCE">
        <om-confirm-insurance
          [source]="source"
          [showBack]="showBack"
          (back)="goBack()"
          (confirmInsurance)="goToStep(InsuranceStep.ADD_INSURANCE)"
          (confirmSelfPay)="goToDestination()"
          (analytics)="trackAnalytics($event)"
        ></om-confirm-insurance>
      </div>
      <div *ngIf="currentStep === InsuranceStep.CONFIRM_EXISTING_INSURANCE">
        <om-confirm-existing-insurance
          [showBack]="true"
          [insurance]="insurance"
          [copayCollectionRequired]="copayCollectionRequired"
          (back)="goBack()"
          (confirm)="goToDestination()"
          (update)="goToStep(InsuranceStep.ADD_INSURANCE)"
          (confirmSelfPay)="goToDestination()"
        ></om-confirm-existing-insurance>
      </div>
      <div *ngIf="currentStep === InsuranceStep.ADD_INSURANCE">
        <om-add-insurance-page
          [source]="source"
          [hasExistingNonSelfPayInsurance]="hasExistingNonSelfPayInsurance"
          [showSkip]="showSkip"
          (back)="goBack()"
          (submit)="goToDestination($event)"
          (analytics)="trackAnalytics($event)"
        >
          ></om-add-insurance-page
        >
      </div>
    </div>
  </div>
</div>
